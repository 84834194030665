import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"

import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isHeading } from "react-datocms/node_modules/datocms-structured-text-utils"
import { Navigator } from "../components/LanguageHelpers/Navigator"

const BlogPost = ({ pageContext, data: { BlogPost, miscString } }) => {
  return (
    <Layout
      pageData={pageContext}
      seoTitle={BlogPost.seo?.seoTitle}
      seoDescription={BlogPost.seo?.seoDescription}
      seoImage={BlogPost.seo?.image?.seoImageUrl}
    >
      <div className="mx-auto max-w-screen-xl p-4" padded>
        <div className="mx-auto flex max-w-screen-md flex-col gap-4 py-4">
          <StructuredText
            data={BlogPost.content}
            renderInlineRecord={({ record }) => {
              return (
                <Navigator
                  recordId={record.id}
                  className="inline-flex items-center text-lg font-medium text-blue-600 hover:font-semibold hover:underline"
                >
                  {record.title}
                </Navigator>
              )
            }}
            renderLinkToRecord={({ record, children }) => {
              return (
                <Navigator recordId={record.id}>
                  <span>{children}</span>
                </Navigator>
              )
            }}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsImageBlock":
                  return (
                    <div style={{ margin: "1rem 0" }}>
                      <GatsbyImage image={record.image?.gatsbyImageData} />
                    </div>
                  )

                case "DatoCmsCtaLink":
                  return (
                    <div className="flex w-full items-center justify-center">
                      <Navigator
                        recordId={record.link.originalId}
                        className="rounded-lg  bg-blue-700 px-5 py-2.5 text-center text-lg font-semibold text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                      >
                        {record.cta}
                      </Navigator>
                    </div>
                  )
              }
            }}
            customNodeRules={[
              renderRule(isHeading, ({ node, children, key }) => {
                const Heading = `h${node.level}`
                return <Heading key={key}>{children}</Heading>
              }),
            ]}
          />
        </div>

        <div className="my-8 flex w-full justify-between gap-8   ">
          {BlogPost.relatedPost[0]?.relatedPost.map(post =>
            post.__typename === "DatoCmsBlogPost" ? (
              <Navigator
                className=" flex flex-1  items-center  rounded bg-lightGray shadow transition-all hover:opacity-90"
                key={post.id}
                recordId={post.id}
              >
                <GatsbyImage
                  image={post.image.gatsbyImageData}
                  style={{ width: "100%" }}
                />
                <div className="flex flex-col gap-4 p-4">
                  <h2 className="text-xl font-semibold">{post.title}</h2>
                  <span className="text-sm font-semibold underline">
                    {" "}
                    {miscString.nodes[0].readMore}
                  </span>
                </div>
              </Navigator>
            ) : (
              <Navigator
                className=" flex flex-1  items-center  rounded bg-lightGray shadow transition-all hover:opacity-90"
                key={post.id}
                recordId={post.id}
              >
                <GatsbyImage image={post.hero.gatsbyImageData} />
                <div className="flex flex-col gap-4 p-4">
                  <span className="font-mono text-sm font-semibold text-deepblue">
                    {post.tag}
                  </span>
                  <h2 className="text-xl font-semibold">{post.title}</h2>
                  <span className="text-sm font-semibold underline">
                    {miscString.nodes[0].readMore}
                  </span>
                </div>
              </Navigator>
            )
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
export const query = graphql`
  query BlogPostQuery($id: String, $locale: String) {
    BlogPost: datoCmsBlogPost(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      content {
        value
        links {
          ... on DatoCmsElevator {
            id: originalId
            __typename
            title
            slug
          }
          ... on DatoCmsContactPage {
            id: originalId
            __typename
            title
            slug
          }
          ... on DatoCmsElectronic {
            id: originalId
            __typename
            title
            slug
          }
          ... on DatoCmsBlogPost {
            id: originalId
            __typename
            title
            slug
          }
        }
        blocks {
          ... on DatoCmsCtaLink {
            id: originalId
            __typename
            cta
            link {
              originalId
            }
          }
          ... on DatoCmsImageBlock {
            __typename
            id: originalId
            caption
            image {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      relatedPost {
        relatedPost {
          ... on DatoCmsBlogPost {
            __typename
            id: originalId
            title
            summary
            image {
              gatsbyImageData(height: 180)
            }
          }
          ... on DatoCmsElevator {
            __typename
            id: originalId
            title
            tag
            description
            hero {
              gatsbyImageData(height: 180)
            }
          }
          ... on DatoCmsElectronic {
            __typename
            id: originalId
            title
            hero {
              gatsbyImageData(height: 180)
            }
          }
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        relatedPosts
        readMore
      }
    }
  }
`
